<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card v-if="form != null">


        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> {{form.form_name | capitalize }}</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          

          <b-col md="4" class="mobile_tab_max_width_flex">
              
              <b-form-group
                  label="Location"
                  class="required mb-0 mobile_tab_margin_bottom_point_five"

                >
                  <b-form-select v-model="location_id">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="l._id" v-for="l in allLocations" :key="l._id">{{l.name | capitalize}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="4" class="mobile_tab_max_width_flex">
              
              <b-form-group
                  label="Checked By"
                  class="required mb-0"

                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="supervisor_name"
                  />
                    
                </b-form-group>
              
          </b-col>

          <b-col md="4">
              
              <b-form-group label="Inspection Date" class="required">
                <b-form-datepicker
                  v-model="checked_date"
                  :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                  locale="en"
                  :max="maxDate"
                />  
              </b-form-group>
              
          </b-col>
          
        </b-row> 
    
      </b-card>



    <div v-if="items != null">

      <b-form @submit="formSubmit">
      
        <b-card>
          
          <b-row class="mb-1">
              <b-col md="1">
                <h5> # </h5>
              </b-col>
              <b-col md="5">
                <h5> Unit Name </h5>
              </b-col>
              
              <b-col md="1">
                <h5> S.No </h5>
              </b-col>
              <b-col md="3">
                <h5> Element Name </h5>
              </b-col>
              <b-col md="2">
                <h5> Checklist </h5>
              </b-col>
          </b-row>
          <hr>
          <div v-for="(c,index) in items">


            <b-row class="mb-1">

                <b-col md="10" class="mb-1">
                  
                  
                </b-col>
                
                <b-col md="2" class="mb-1">
                  
                    <b-form-checkbox
                      value="yes"
                      unchecked-value="no"
                      class="custom-control-warning"
                      @change="selectAll($event,index)"
                    > Select All
                  </b-form-checkbox>

                </b-col>
            </b-row>

            <b-row class="mb-0">

                <b-col md="1" class="mb-1">
                  {{index + 1}}
                </b-col>

                <b-col md="5" class="mb-1">
                  <p class="wordBreak"> {{c.unit_name}} </p>
                </b-col>
                

                
                <b-col md="1" class="mb-1">
                  1
                </b-col>
                <b-col md="3" class="mb-1">
                  <p class="wordBreak">{{c.elements[0].name}} </p>
                </b-col>
                
                <b-col md="2" class="mb-1">
                  
                    
                    
                    <b-form-checkbox
                      v-model="c.elements[0].checked"
                      value="yes"
                      unchecked-value="no"
                      class="custom-control-warning"
                    />

                </b-col>
            </b-row>

            <b-row class="mb-0" v-for="(element,k) in c.elements" v-if="c.elements.length > 1" :key="k">
                <b-col md="1" v-if="k > 0" class="mb-3">
                  
                </b-col>
                <b-col md="3" v-if="k > 0" class="mb-3">
                  
                </b-col>
                <b-col md="2" v-if="k > 0" class="mb-3">
                  
                </b-col>
                <b-col md="1" v-if="k > 0" class="mb-3">
                  {{k + 1}}
                </b-col>
                <b-col md="3" v-if="k > 0" class="mb-3">
                  {{element.name}}
                </b-col>
                

                <b-col md="2" v-if="k > 0 " class="mb-3">
                  <!-- <p>{{element.checked}}</p> -->
                  <b-form-checkbox
                      v-model="element.checked"
                      value="yes"
                      unchecked-value="no"
                      class="custom-control-warning"
                    />
                </b-col>
            </b-row>
            <b-row>
            
              <b-col md="12">
                  
                  <b-form-group
                      label="Comment"
                      class=""
                    >
                      <b-form-textarea
                        
                        v-model="c.comment"
                        placeholder=""
                      />
                        
                    </b-form-group>
                  
              </b-col>
            </b-row>

            <hr>
          </div>

          <!-- <b-row>
                
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Upload Images
              </h4>
              
            </b-col>
          </b-row>

          <b-row>

            <b-col
              cols="10"
              class="mb-2 mt-2"
            > 
              <b-row>
                <b-col md="3" v-for="(bi,index) in images" :key="index">
                      <b-media class="mb-2 mt-2" @click="$refs['biInput' +index ][0].click()" v-if="typeof bi._id == 'undefined'">
                        <template #aside>
                          <b-avatar
                            :ref="'bI' + index"
                            :src="bi.image != null ? bi.image : doc_icon"
                            variant="light-warning"
                            size="200px"
                            rounded
                          />
                        </template>

                        <div class="d-flex flex-wrap">
                          <input
                            :ref="'biInput' + index"
                            type="file"
                            class="d-none"
                            @input="biInput($event,index)"
                          >
                        </div>

                        
                      </b-media>


                      
                      <b-button
                          variant="danger"
                          class="mt-0 ml-5 mr-0"
                          @click="removeImage(index)"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>

                      
                </b-col>
                
                

              </b-row>
            </b-col>

            <b-col md="2" >
              <b-button
                  variant="warning"
                  class="mt-2 ml-50 mr-0 float-right"
                  @click="addImage()"
                  v-if="images.length < 20"
                >
                <feather-icon icon="PlusCircleIcon" />
                <span class="align-middle ml-50">Add More</span>
              </b-button>
            </b-col>
          </b-row> -->

          

              <b-row>

                <!--<b-col
                  cols="5"
                  class="mb-2 mt-2"
                > 
                </b-col>-->
                <b-col
                  cols="12"
                  class="mb-2 mt-2"
                > 

                  
                
                    <b-col
                      
                      class="mb-2  mobile_pdng_lft_rgt_zero"
                      ><center>
                        <h4 class="mb-0 align_start_mobile">
                          Signature
                        </h4>
                        </center>
                      </b-col>
                  

                  
                      <b-col
                        
                        class="mb-2 mt-0 mobile_pdng_lft_rgt_zero full_height_width_img_mobile margin_bottom_zero_mobile" style="text-align: center"
                      > 
                    
                          <b-media class="mb-2 mt-2 desktop_display_block" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                            <template #aside>
                              <b-avatar
                                ref="supervisorS"
                                :src="doc_icon"
                                variant="light-info"
                                size="200px"
                                rounded
                              />
                            </template>

                            <div class="d-flex flex-wrap">
                              <input
                                ref="supervisorSInput"
                                type="file"
                                class="d-none"
                                @input="supervisorImageUpload"
                              >
                            </div>

                            
                          </b-media>


                          <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                        
                          <center class="">
                            <b-button
                                variant="danger"
                                class="mt-1 ml-2"
                                v-if="supervisorSign.image != null"
                                @click="removeSign('supervisor')"
                              >
                              <feather-icon icon="XIcon" />
                              
                            </b-button>
                          </center>


                        </b-col>
                  
                </b-col>
              </b-row>
            
            <!-- <b-col
              cols="6"
              class="mb-2 mt-2"
            > 
              <b-row>
                
                  <b-col
                  cols="12"
                  class="mb-2 mt-2"
                  >
                    <h4 class="mb-0">
                      Client Signature
                    </h4>
                    
                  </b-col>
              </b-row>

              <b-row>
                  <b-col
                    cols="6"
                    class="mb-2 mt-0"
                  > 
                
                      <b-media class="mb-2 mt-2" @click="openSignature('client')">
                        <template #aside>
                          <b-avatar
                            ref="clientS"
                            :src="clientSign.image != null ? clientSign.image : doc_icon"
                            variant="light-danger"
                            size="200px"
                            rounded
                          />
                        </template>

                        <div class="d-flex flex-wrap">
                          <input
                            ref="clientSInput"
                            type="file"
                            class="d-none"
                            @input="clientImageUpload"
                          >
                        </div>

                        
                      </b-media>

                      
                    </b-col>
              </b-row>
            </b-col> -->

            
          </b-row>

          <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mr-1"
                  type="submit"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'update-checklists' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>
        </b-card>

      </b-form>

      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>



    </div>

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox , BBreadcrumb, BFormDatepicker
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';

var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox , BBreadcrumb, BFormDatepicker
  },

  computed: {
    maxDate() {
      return new Date();
    },
  },
  
  data() {
    return {
      checked_date : moment().format('YYYY-MM-DD'),
      items:null,
      form:null,

      crew:'',
      allCrew:[],

      location_id:'',
      allLocations:[],

      supervisor_name:this.$store.getters.currentUser.full_name,

      showDismissibleAlert:false,

      images:[{
        image:null,
        name:'',
      }],
      clientSign : {
        image:null,
        name:'',
      },
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,

      
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    
    variantColor(status){
      if (status == 'assigned') {
        return 'primary';
      }else if(status == 'in_progress'){
        return 'warning'
      }else{
        return 'success';
      }
    },

    checklistDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id,
           },
           api: '/api/assigned-checklist-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.items = this.$store.getters.getResults.data.checklist;
                this.form = this.$store.getters.getResults.data.form;

                this.allCrewList();
                this.allLocationsList();
                return this.items;
            }
        });
    },
    allCrewList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
           },
           api: '/api/all-crew'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allCrew = this.$store.getters.getResults.data;
                this.crew = this.allCrew.length > 0 ? this.allCrew[0]._id : '';
                return this.allCrew;
            }
        });
    },
    allLocationsList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             type:'QI'
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allLocations = this.$store.getters.getResults.data;
                return this.allLocations;
            }
        });
    },
    biInput(event,index){
      //console.log(event,index);

      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['biInput'+index][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['biInput'+index][0].value=null;
              

          } else {
              reader.onload = (e) => {

                this.images[index].image = e.target.result;
                this.images[index].name = image.name;

            }
          }
      }
    },
    addImage(){
      var bi = {
        image:null,
        name:'',
      }

      this.images.push(bi);
    },
    removeImage(index){
      this.images.splice(index,1);

      if (this.images.length == 0) {
        this.addImage();    
      }
    },
    
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type =  'supervisor';
                this.supervisorSign.default = 'no';
                

            }
          }
      }
    },
    clientImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.clientSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.clientSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.clientSign.image = e.target.result;
                this.clientSign.name = image.name;
                

            }
          }
      }
    },
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
            checked_date: this.checked_date,
             crew:this.crew,
             location_id:this.location_id,
             supervisor_name:this.supervisor_name,
             images:this.images,
             clientSign:this.clientSign,
             supervisorSign:this.supervisorSign,
             checklists:this.items,
             form_id:this.$route.params.id,

           },
           api: '/api/submit-checklist'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                
                var data = this.$store.getters.getResults.data;
                

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Checklist Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'update-checklists' })
                });

                //return data;
            }
        });
    },
    selectAll(e,index){
      
      this.items[index].elements.forEach(item => {
        item.checked = e; 
      });
    },
    
    removeSign(sign){

      this.supervisorSign.image = null;
      this.supervisorSign.name = '';
      this.supervisorSign.default = 'no'; 
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'inspected_by') {

         this.$refs['inspectedSInput'].click();
      }

      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor') {
          
          this.supervisorSign.image = data;
          this.supervisorSign.name = 'siganture.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';

          
        }else{

          this.clientSign.image = data;
          this.clientSign.name = 'signature.png';
          

        }

        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){
      
      if (this.openedSignModel == 'supervisor') {
                    
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        
      }else{

        this.clientSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type: 'client',
          default:'yes'
        }
      }

      this.$refs['signatureModel'].hide();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'update-checklists'},
        text: 'Create Checklists'
      },{
        to:null,
        text: 'Form Detail',
        active:true
      }];
      return item;
    }
    
  },
  mounted(){
    this.useDefault();
    this.checklistDetail();
    
    
    
  }
  
  
}
</script>

<style>

</style>
